/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import "./image-modal.css"

const ImageModal = ({ onClose, onZoom, data, isZoomIn }) => {
  return (
    <div
      id="myNav"
      className="overlay"
      style={{ width: "100%", zIndex: "9999" }}
    >
      <a
        className="closebtn"
        onClick={onClose}
        style={{ zIndex: "99999", cursor: "pointer" }}
      >
        &times;
      </a>
      <div
        className="overlay-content"
        style={{ padding: "0px" }}
        onClick={onZoom}
      >
        {/* <Img
                    sx={{
                        maxWidth: isZoomIn ? 1400 : 700,
                        mx: "auto",
                        cursor: !isZoomIn ? 'zoom-in' : 'zoom-out',
                    }}
                    alt={data.title}
                    fluid={data.fluid}
                    key={data.id}
                /> */}
        <img src={data.fluid.src.split("?")[0]} />
      </div>
    </div>
  )
}

export default ImageModal
