import React from "react"

import theme from "../gatsby-plugin-theme-ui"

import { useScrollYPosition } from "./use-scroll-position"
import { useThrottle } from "./use-throttle"

const initialState = { action: "none" }

export const useSticky = (height = theme.sizes.header.height, delay = 200) => {
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "STICKY_EVENT":
        return {
          ...state,
          ...action.payload,
        }

      default:
        return state
    }
  }, initialState)
  const currentScrollY = useScrollYPosition()
  const lastKnownScrollY = React.useRef(currentScrollY)
  const throttledScrollY = useThrottle(currentScrollY, delay)

  React.useEffect(() => {
    const scrollDirection =
      currentScrollY >= lastKnownScrollY.current ? "down" : "up"

    lastKnownScrollY.current = throttledScrollY

    if (currentScrollY <= height) {
      dispatch({
        type: "STICKY_EVENT",
        payload: { action: "none" },
      })
    } else if (scrollDirection === "down" && currentScrollY > height) {
      dispatch({
        type: "STICKY_EVENT",
        payload: { action: "pin" },
      })
    } else if (scrollDirection === "up" && currentScrollY === height) {
      dispatch({
        type: "STICKY_EVENT",
        payload: { action: "unpin" },
      })
    }
  }, [throttledScrollY, currentScrollY, height])

  return state
}
