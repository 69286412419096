/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "./modal"
import Form from "./form"
import { fromPairs } from "lodash"

const GatewayForm = ({
  onClose,
  onSubmit,
  detail,
  goalName,
  conversionDetail,
  plansDownloadModel,
  addFields,
  addFieldsDefault,
  sizeSQFT,
  downloadPlan
}) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSection(slug: { eq: "gateway-form" }) {
        content {
          internal {
            content
          }
        }
      }
    }
  `)
  let formData = JSON.parse(data.contentfulSection.content.internal.content)
  if (formData.detailField && detail)
    formData.defaultFields = {
      ...formData.defaultFields
      // ,
      // [formData.detailField]: detail,
    }
  if (formData.gaEventLabel && goalName) formData.gaEventLabel = goalName
  if (formData.conversionDetail && conversionDetail)
    formData.conversionDetail = conversionDetail
  // if (plansDownloadModel) {
  //   formData.defaultFields["Plan Download Model"] = plansDownloadModel
  // }
  // if (addFields) {
  //   formData = { ...formData, ...addFields }
  // }
  // if (addFieldsDefault) {
  //   formData.defaultFields = { ...formData.defaultFields, ...addFieldsDefault }
  // }

  // console.log("Form==>",formData)

  return (
    <Modal
      title={"Download Plans"}
      text={"Please answer a few quick questions about your project."}
      customStyle={{ maxWidth: [null, null, null, 700] }}
      onClose={onClose}
    >
      <Form
        formData={formData}
        onSubmit={onSubmit}
        noSteps={true}
        type="secondary"
        sizeSQFT={sizeSQFT}
        modalClose={onClose}
        downloadPlan={downloadPlan}
      ></Form>
    </Modal>
  )
}

export default GatewayForm
